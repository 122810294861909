import { Component } from '@angular/core';

@Component({
  selector: 'app-power-control',
  standalone: true,
  imports: [],
  templateUrl: './power-control.component.html',
  styleUrls: ['./power-control.component.css', '.././products/products.component.css']
})
export class PowerControlComponent {

}
