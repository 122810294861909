import { Component, HostListener } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule, ButtonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
})
export class NavbarComponent {
  isMobileMenuActive: boolean = false;
  isProductsDropdownActive: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 768) {
        this.isMobileMenuActive = false;
        this.isProductsDropdownActive = false;
      }
    }

  }

  toggleMobileMenu() {
    this.isMobileMenuActive = !this.isMobileMenuActive;
    if (!this.isMobileMenuActive) {
      this.isProductsDropdownActive = false;
    }
  }

  toggleProductsDropdown(event: Event) {
    event.preventDefault();
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 768) {
        this.isProductsDropdownActive = !this.isProductsDropdownActive;
      }
    }
  }

  closeMobileMenu() {
    this.isMobileMenuActive = false;
    this.isProductsDropdownActive = false;
  }

}
