import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card'
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, RouterLink } from '@angular/router';
import { CarouselComponent } from './carousel/carousel.component';

interface Slide {
  type: 'text' | 'image';
  title?: string;
  content?: string;
  imageUrl?: string;
  alt?: string;
  objectPosition?: string;
  isFirstSlide?: boolean;
}
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [ButtonModule, CardModule, CommonModule, RouterModule, CarouselComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  @ViewChild('clientContainer') clientContainer!: ElementRef;
  slides: Slide[] = [];
  isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  // services = [
  //   {
  //     title: 'Service 1',
  //     image: 'assets/img/Main.jpg',
  //     description: 'Description for Service 1',
  //     icon: 'pi pi-power-off'
  //   },
  //   {
  //     title: 'Service 2',
  //     image: 'assets/img/Main.jpg',
  //     description: 'Description for Service 2',
  //     icon: 'pi pi-cog'
  //   },
  //   {
  //     title: 'Service 3',
  //     image: 'assets/img/Main.jpg',
  //     description: 'Description for Service 3',
  //     icon: 'pi pi-globe'
  //   },
  // ];

  products = [
    {
      title: 'Power Control',
      description: 'Ensure uninterrupted operations with our reliable UPS and power management technologies.',
      icon: 'pi pi-bolt'
    },
    {
      title: 'Infrastructure',
      description: 'Build resilient, efficient networks with our advanced data center and IT infrastructure systems.',
      icon: 'pi pi-server'
    },
    {
      title: 'Renewable Energy',
      description: 'Harness clean, sustainable power with our cutting-edge solar solutions for homes and businesses.',
      icon: 'pi pi-sun'
    }
  ];

  clients = [
    { name: 'Client 1', logo: 'assets/img/slides/Slide1.png' },
    { name: 'Client 2', logo: 'assets/img/slides/Slide2.jpg' },
    { name: 'Client 3', logo: 'assets/img/slides/Slide3.jpg' },
    { name: 'Client 4', logo: 'assets/img/slides/Slide4.png' },
    { name: 'Client 5', logo: 'assets/img/slides/Slide5.png' },
    { name: 'Client 6', logo: 'assets/img/slides/Slide6.jpg' },
    { name: 'Client 7', logo: 'assets/img/slides/Slide7.jpg' },
    { name: 'Client 8', logo: 'assets/img/slides/Slide8.jpg' },
    { name: 'Client 9', logo: 'assets/img/slides/Slide9.png' },
    { name: 'Client 10', logo: 'assets/img/slides/Slide10.png' },
    { name: 'Client 11', logo: 'assets/img/slides/Slide11.png' },
    { name: 'Client 12', logo: 'assets/img/slides/Slide 12.jpg' },
    { name: 'Client 13', logo: 'assets/img/slides/Slide 13.jpg' },
    { name: 'Client 14', logo: 'assets/img/slides/Slide 14.jfif' },
    { name: 'Client 15', logo: 'assets/img/slides/Slide 15.jfif' },
    { name: 'Client 16', logo: 'assets/img/slides/Slide 16.png' },
    { name: 'Client 17', logo: 'assets/img/slides/Slide 17.png' },
    { name: 'Client 18', logo: 'assets/img/slides/Slide 18.jfif' },
  ];
  ngOnInit() {
    this.slides = [
      {
        type: 'image',
        imageUrl: 'assets/img/slides/Carousel 6.jpg',
        alt: 'Carousel 1',
        objectPosition: '35% 9%',
      },
      {
        type: 'text',
        title: 'Keeping you powered, cooled and protected.',
        // content: 'Power supply is interrupted by Surges, Spikes and Outages that can degrade the performance of your equipment and shorten its lifespan.'
      },
      {
        type: 'image',
        imageUrl: 'assets/img/slides/Carousel 2.jpg',
        alt: 'Carousel 2',
        objectPosition: 'bottom'
      },
      {
        type: 'text',
        title: 'Providing the most sustainable and innovative energy solutions.',
        // content: 'Although power is expected to be constantly available at an acceptable voltage, current and frequency, the reality is that it is unreliable.'
      },
      {
        type: 'image',
        imageUrl: 'assets/img/slides/Carousel 3.jpg',
        alt: 'Carousel 3'
      },
      {
        type: 'text',
        title: 'Technical expertise that is APC- Schneider Electric trained, giving you world-class service.',
        // content: 'It is important to ensure that your equipment is always protected to avoid loss of information and costly downtime.'
      },
      {
        type: 'image',
        imageUrl: 'assets/img/slides/Carousel 4.png',
        alt: 'Carousel 4'
      }, {
        type: 'text',
        title: 'We maximise your uptime.',
        // content: 'It is important to ensure that your equipment is always protected to avoid loss of information and costly downtime.'
      },
      {
        type: 'image',
        imageUrl: 'assets/img/slides/Carousel 5.jpg',
        alt: 'Carousel 5',
        objectPosition: 'bottom'
      },
      {
        type: 'text',
        title: 'Our goal is to consistently meet customer service delivery, support and value expectations.',
        // content: 'It is important to ensure that your equipment is always protected to avoid loss of information and costly downtime.'
      },
    ];

    this.checkScreenSize();
  }

  checkScreenSize() {
    if (typeof window !== "undefined") {
      this.isMobile = window.innerWidth <= 768;
    }
  }

  scrollClients(direction: 'left' | 'right') {
    const container = this.clientContainer.nativeElement;
    const clientWidth = container.querySelector('.client').offsetWidth;
    const scrollAmount = this.isMobile ? clientWidth : container.clientWidth;

    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }
}
