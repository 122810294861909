<div class="home-container">
    <div class="top-section">
        <div class="content-wrapper">
            <div class="text-content">
                <h1>Market Leader in Power Control and Renewable Energy</h1>
                <p>International Power Control Systems is an established and leading company in Power Control and
                    Renewable Energy. We cater for all sectors of industry in supplying, installing, servicing and
                    maintaining complete Data Centers, UPSs, Automatic Voltage Regulators, Solar Systems, including
                    but not limited to undertaking electrical installation of all the mentioned equipment</p>
                <p-button routerLink="/contact" label="Get in Touch"></p-button>
            </div>
            <div class="image-content">
                <div class="lightbulb-container">
                    <div class="bulb">
                        <div class="filament">
                            <div class="filament-top"></div>
                            <div class="filament-bottom"></div>
                        </div>
                    </div>
                    <div class="bulb-base"></div>
                    <div class="screw"></div>
                </div>
            </div>
        </div>
    </div>

    <section class="slider-section">
        <div class="slider-container">
            <app-carousel [slides]="slides"></app-carousel>
        </div>
    </section>


    <!-- <div class="services-section">
        <h2>Our Services</h2>
        <div class="grid">
            <div class="col-12 md:col-4" *ngFor="let service of services">
                <div class="service-card">
                    <div class="card-content">
                        <div class="icon-container">
                            <i [class]="service.icon"></i>
                        </div>
                        <h3 class="card-title">{{service.title}}</h3>
                        <p>{{service.description}}</p>
                        <button class="p-button p-button-secondary">Find out more</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->



    <div class="products-section">
        <h2 class="section-title">Our Products</h2>
        <div class="grid">
            <div class="col-12 md:col-4" *ngFor="let product of products">
                <div class="product-card">
                    <div class="card-content">
                        <div class="icon-container">
                            <i [class]="product.icon"></i>
                        </div>
                        <h3 class="card-title">{{product.title}}</h3>
                        <p>{{product.description}}</p>
                        <a [routerLink]="['/products/', product.title.toLowerCase().replace(' ', '-')]"
                            class="p-button p-button-secondary">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="clients-section">
        <h2 class="section-title">Our Satisfied Clients</h2>
        <div class="client-carousel">
            <button class="scroll-button left" (click)="scrollClients('left')">
                <i class="pi pi-chevron-left"></i>
            </button>
            <div class="client-container" #clientContainer>
                <div class="client" *ngFor="let client of clients">
                    <div class="client-card">
                        <img [src]="client.logo" [alt]="client.name + ' logo'" class="client-logo" loading="lazy">
                        <!-- <div class="client-overlay">
                            <span class="client-name">{{client.name}}</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <button class="scroll-button right" (click)="scrollClients('right')">
                <i class="pi pi-chevron-right"></i>
            </button>
        </div>
    </section>

    <section class="meet-the-team">
        <h2 class="section-title">Meet the Team</h2>

        <div class="team-member image-right">
            <div class="member-info">
                <h3>Tawanda Madovi</h3>
                <h4>Managing Director</h4>
                <p>A visionary transformational leader with 29 years of experience in driving growth, innovation and
                    sustainability in the power control and renewable energy sectors. Through his expertise and
                    dedication, Tawanda has made a tremendous impact in communities across Malawi, the region and the
                    world.</p>
            </div>
            <div class="member-image">
                <div class="image-container">
                    <div class="circle-overlay"></div>
                    <img src="assets/img/management/MD.jpg" alt="John Doe - Managing Director" loading="lazy">
                </div>
            </div>
        </div>

        <div class="team-member image-left">
            <div class="member-image">
                <div class="image-container">
                    <div class="circle-overlay"></div>
                    <img src="assets/img/management/BDMD.jpg" alt="Jane Smith - Financial Director" loading="lazy">
                </div>
            </div>
            <div class="member-info">
                <h3>Rumbidzai Bere</h3>
                <h4>Business Development & Marketing Director</h4>
                <p>Brings immense strategic input that has become a game changer in the industry, with 25 years’
                    experience in communications, project management, strategic marketing and business development. Has
                    a strong advertising background and demonstrable excellence in branding and business unit
                    development. </p>
            </div>
        </div>

        <div class="team-member image-right">
            <div class="member-info">
                <h3>Lawson Ntasa</h3>
                <h4>Technical Director</h4>
                <p>Invested 28 years of driving high-performance technology in the power industry, leading high
                    performing teams to deliver innovative and sustainable solutions. Lawson has extensive engineering
                    experience in financial, manufacturing and developmental projects.</p>
            </div>
            <div class="member-image">
                <div class="image-container">
                    <div class="circle-overlay"></div>
                    <img src="assets/img/management/TD.jpg" alt="Mike Johnson - Marketing Director" loading="lazy">
                </div>
            </div>
        </div>

        <div class="team-member image-left">
            <div class="member-image">
                <div class="image-container">
                    <div class="circle-overlay"></div>
                    <img src="assets/img/management/AAO.jpg" alt="Sarah Lee - Technical Director" loading="lazy">
                </div>
            </div>
            <div class="member-info">
                <h3>Happy Mwankenja</h3>
                <h4>Accounts & Administration Officer</h4>
                <p>Highly organized and detail oriented, with 14 years’ experience in ensuring seamless financial
                    operations. Happy has maintained an excellent interpersonal skill that has built strong
                    relationships with the various stakeholders.</p>
            </div>
        </div>
        <div class="team-member image-right">
            <div class="member-info">
                <h3>Kelvin Kassam</h3>
                <h4>Sales & Administration Officer</h4>
                <p>A results-driven and extremely reliable officer who has significantly contributed to the business
                    growth for over 21 years. Kelvin consistently exceeds sales targets, possesses unmatched initiative
                    that results in effective administrative management.</p>
            </div>
            <div class="member-image">
                <div class="image-container">
                    <div class="circle-overlay"></div>
                    <img src="assets/img/management/Kelvin 3.jpg" alt="Mike Johnson - Marketing Director"
                        loading="lazy">
                </div>
            </div>
        </div>
    </section>
</div>