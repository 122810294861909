import { Component } from '@angular/core';

@Component({
  selector: 'app-renewable-energy',
  standalone: true,
  imports: [],
  templateUrl: './renewable-energy.component.html',
  styleUrls: ['./renewable-energy.component.css', '.././products/products.component.css']
})
export class RenewableEnergyComponent {

}
