<div class="product-page power-control">
    <h1 class="section-title">Power Control Solutions</h1>

    <section class="product-section">
        <h2>AVR & Surge Protection</h2>
        <div class="product-group">
            <div class="product-images">
                <img src="/assets/img/products/AVR & Surge Protection 4.JPG" alt="AVR & Surge Protection 1"
                    class="product-image" loading="lazy">
                <img src="/assets/img/products/AVR & Surge Protection 5.JPG" alt="AVR & Surge Protection 2"
                    class="product-image" loading="lazy">
                <img src="/assets/img/products/AVR & Surge Protection 3.jpg" alt="AVR & Surge Protection 3"
                    class="product-image" loading="lazy">
                <img src="/assets/img/products/AVR & Surge Protection 6.png" alt="AVR & Surge Protection 4"
                    class="product-image" loading="lazy">
            </div>
            <div class="product-info">
                <p>Protect your equipment from instant and chronic damage.</p>
                <button class="cta-button"><a href="/contact">Get SurgeArrest with USB Ports</a></button>
            </div>
        </div>
    </section>

    <section class="product-section">
        <h2>DC & Back-UPS</h2>
        <div class="product-group">
            <div class="product-images">
                <img src="/assets/img/products/DC & Back-UPS 1.jpg" alt="DC & Back-UPS 1" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/DC & Back-UPS 4.JPG" alt="DC & Back-UPS 4" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/DC & Back-UPS 3.jpg" alt="DC & Back-UPS 3" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/DC & Back-UPS 5.JPG" alt="DC & Back-UPS 5" class="product-image"
                    loading="lazy">
            </div>
            <div class="product-info">
                <p>Eliminate abrupt power interruption and protect your equipment.</p>
                <p>Stay connected for Remote Learning, Working from Home and Entertainment.</p>
            </div>
        </div>
    </section>

    <section class="product-section">
        <h2>Easy-Range APC</h2>
        <div class="product-group">
            <div class="product-images">
                <img src="/assets/img/products/Easy Range New 1.JPG" alt="Easy Range 1" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Easy Range New 2.JPG" alt="Easy Range 2" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Easy Range 5.jpg" alt="Easy Range 5" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Easy Range Family 1.jpg" alt="Easy Range Family 1" class="product-image"
                    loading="lazy">

            </div>
            <div class="product-info">
                <p>Quality products for price-conscious customers, providing power protection and backup in unstable
                    power conditions.</p>
                <ul>
                    <li>Basic power back-up at an affordable price</li>
                    <li>Back-UPSs that can also be wall-mount</li>
                    <li>Single-Phase Essential Series available in Line Interactive and Online Topology</li>
                    <li>Three-Phase Core Market for Data Center and Industry clean environment</li>
                </ul>
            </div>
        </div>
    </section>

    <section class="product-section smart-ups-section">
        <h2>Smart-UPS</h2>
        <div class="product-group">
            <div class="product-images">
                <!-- <img src="/assets/img/products/Smart Range New 1.JPG" alt="Smart-UPS 1" class="product-image"> -->
                <img src="/assets/img/products/Smart Range New 2.JPG" alt="Smart-UPS 2" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Smart Range New 3.JPG" alt="Smart-UPS 3" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Smart Range New 4.JPG" alt="Smart-UPS 4" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Smart Range Family 1.jpg" alt="Smart-Range Family 1"
                    class="product-image" loading="lazy">

            </div>
            <div class="product-info">
                <p>Performance series to suit your load.</p>
                <ul>
                    <li>Single Phase in Essential Series, Advanced Series and Performance Series</li>
                    <li>Available in Line Interactive and Online Topology, with internal and extended battery</li>
                    <li>Three-Phase Premium and Modular Markets for Data Center, Industry clean and harsh environments
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <section class="product-section">
        <h2>Rack, PDU & Accessories</h2>
        <div class="product-group">
            <div class="product-images">
                <img src="/assets/img/products/Accessories 1.jpg" alt="Accessories 1" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Accessories 2.jpg" alt="Accessories 2" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Accessories 6.JPG" alt="Accessories 3" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Accessories 4.jpg" alt="Accessories 4" class="product-image"
                    loading="lazy">
            </div>
            <div class="product-info">
                <p>For all your IT Network enclosures, Server Rooms and Data Centers</p>
                <button class="cta-button"><a href="/contact">Contact us for Data Center accessories</a></button>
            </div>
        </div>
    </section>

    <section class="product-section">
        <h2>Lithium Battery</h2>
        <div class="product-group">
            <div class="product-images">
                <img src="/assets/img/products/Lithium Battery 1.jpg" alt="Lithium Battery 1" class="product-image"
                    loading="lazy">
                <img src="/assets/img/products/Lithium Battery 2.jpg" alt="Lithium Battery 2" class="product-image"
                    loading="lazy">
            </div>
            <div class="product-info">
                <ul>
                    <li>~40% Reduced Size</li>
                    <li>~40% Reduced Weight</li>
                    <li>2-3X Power Density</li>
                    <li>2X Extended Battery Life</li>
                    <li>Faster Recharge Time</li>
                </ul>
            </div>
        </div>
    </section>
</div>