<footer class="site-footer">
    <div class="footer-content">
        <div class="footer-section about">
            <h3>About Us</h3>
            <p>International Power Control Systems: Innovative solutions and trusted partnerships across Malawi.</p>
        </div>
        <div class="footer-section links">
            <h3>Quick Links</h3>
            <ul>
                <li><a href="/home">Home</a></li>
                <li><a href="/products/power-control">Products - Power Control</a></li>
                <li><a href="/products/infrastructure">Products - Infrastructure</a></li>
                <li><a href="/products/renewable-energy">Products - Renewable Energy</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </div>
        <div class="footer-section contact">
            <h3>Contact Us</h3>
            <div class="office">
                <h4>Head Office - Blantyre</h4>
                <p><i class="pi pi-map-marker"></i> IPCS House, 16 Laws Road, Behind Reserve Bank of Malawi</p>
                <p><i class="pi pi-envelope"></i> Private Bag 197, Blantyre</p>
                <p><i class="pi pi-phone"></i> +265 992 562 686</p>
                <p><i class="pi pi-envelope"></i> ipcs&#64;ipcs.mw</p>
            </div>
            <div class="office">
                <h4>Lilongwe Branch</h4>
                <p><i class="pi pi-map-marker"></i> Area 3, Behind NBS Bank, Next to Tutla Shop</p>
                <p><i class="pi pi-phone"></i> +265 992 562 686</p>
                <p><i class="pi pi-envelope"></i> ipcs&#64;ipcs.mw</p>
            </div>
        </div>
        <div class="footer-section social">
            <h3>Follow Us</h3>
            <div class="social-icons">
                <a href="#" class="social-icon"><i class="pi pi-facebook"></i></a>
                <a href="#" class="social-icon"><i class="pi pi-twitter"></i></a>
                <a href="#" class="social-icon"><i class="pi pi-linkedin"></i></a>
                <a href="#" class="social-icon"><i class="pi pi-instagram"></i></a>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <p>&copy; 2024 IPCS. All rights reserved.</p>
    </div>
</footer>