<div class="about-us-container">
    <h2 class="section-title">About Us</h2>

    <div class="about-grid">
        <div class="about-item who-we-are">
            <h3>Who We Are</h3>
            <p>International Power Control Systems is a fully Malawian and wholly, privately owned company. Established
                in 1998, we've become the market leader in Power Control and Renewable Energy.</p>
        </div>

        <div class="about-item our-mission">
            <h3>Our Mission</h3>
            <p>To provide high-quality Power Protection, Energy Management and Renewable Energy systems with world-class
                after-sales service, reducing energy consumption and equipment replacement costs for Malawi and beyond.
            </p>
        </div>

        <div class="about-item our-values">
            <h3>Our Values</h3>
            <ul class="values-list">
                <li>Reliablity</li>
                <li>Sustainability</li>
                <li>Innovative</li>
                <li>Honesty</li>
                <li>Ethical</li>
                <li>Efficiency</li>
                <li>Dynamic</li>
                <li>Creativity</li>
                <li>Initiative</li>
                <li>Dedication</li>
            </ul>
            <p>Above all, we strongly believe in teamwork.</p>
        </div>

        <div class="about-item what-we-do">
            <h3>What We Do</h3>
            <ul class="services-list">
                <li>Design tailor-made power solutions</li>
                <li>Supply, Install, Service and Maintain UPSs</li>
                <li>Provide complete Data Center Solutions</li>
                <li>Handle Automatic Voltage Regulators and SurgeArrest Extensions</li>
                <li>Offer Standard and Modern Solar Systems with lithium expertise</li>
            </ul>
        </div>

        <div class="about-item our-commitment">
            <h3>Our Commitment</h3>
            <p>We are dedicated to being the best choice for innovative products and reliable complete service. Our
                goal:
                consistently meet customer expectations – "the Service is the Product". Our customers drive our
                business, and we aim for continuous improvement in all aspects.</p>
        </div>
    </div>
</div>