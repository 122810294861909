<div class="product-page renewable-energy">
    <h1 class="section-title">Renewable Energy Solutions</h1>

    <section class="product-section intro">
        <h2>Clean energy that is simply reliable!</h2>
        <p>International Power Control Systems, licensed by Malawi Energy Regulatory Authority (MERA), has managed to
            establish itself as a provider of sustainable and reliable energy solutions. Since 1998, we have provided
            full solar installations. In partnership with Schneider Electric, Sustain Solar and other reliable
            suppliers, we introduced innovative portable solar products and lithium solutions. We are committed to
            increasing access to clean energy in Malawi and the rest of Africa!</p>
    </section>

    <section class="product-section">
        <h2>Our Product Range</h2>
        <div class="product-grid">
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/mobaya full.png" alt="Mobiya Portable Solar System"
                        class="product-image" loading="lazy">
                </div>
                <h3>Mobiya Portable Solar System</h3>
                <p>Portable and efficient solar energy solution for off-grid areas.</p>
            </div>
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/Solar Panels.jpg" alt="Solar Panels and Lithium Battery"
                        class="product-image" loading="lazy">
                </div>
                <h3>Solar Panels and Lithium Battery</h3>
                <p>High-performance solar panels paired with long-lasting lithium batteries.</p>
            </div>
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/Solar Home System .jpg" alt="Solar Home System"
                        class="product-image" loading="lazy">
                </div>
                <h3>Solar Home System</h3>
                <p>Complete solar energy solution for residential use.</p>
            </div>
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/Solar Streetpole.jpg" alt="Solar Street Pole"
                        class="product-image" loading="lazy">
                </div>
                <h3>Solar Street Pole</h3>
                <p>Efficient street pole solution powered by solar energy.</p>
            </div>
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/Change-over Switch & Sub-Distribution Board.jpg"
                        alt="Change-over Switch & Sub Distribution Board" class="product-image" loading="lazy">
                </div>
                <h3>Change-over Switch & Sub Distribution Board</h3>
                <p>Essential components for managing your solar energy system.</p>
            </div>
            <div class="product-item">
                <div class="product-image-container">
                    <img src="/assets/img/product-range/SustainBox 3.jpg" alt="Sustain Box" class="product-image"
                        loading="lazy">
                </div>
                <h3>Sustain Box</h3>
                <p>Innovative containerised, high quality and durable turn-key solutions.</p>
            </div>
        </div>
    </section>

    <section class="product-section cta">
        <h2>Ready to Embrace Clean Energy?</h2>
        <p>Contact us today to learn more about our renewable energy solutions and how they can benefit you.</p>
        <button class="cta-button"><a href="/contact">Get in Touch</a></button>
    </section>
</div>