<section class="contact-us-section">
    <div class="container">
        <h2 class="section-title">Contact Us</h2>
        <div class="contact-content">
            <div class="contact-info">
                <h3>Get in Touch</h3>
                <p>We'd love to hear from you. Feel free to reach out using the form or contact details below.</p>
                <div class="office-info">
                    <h4>Head Office - Blantyre</h4>
                    <p><i class="pi pi-map-marker"></i> IPCS House, 16 Laws Road, Behind Reserve Bank of Malawi</p>
                    <p><i class="pi pi-envelope"></i> Private Bag 197, Blantyre</p>
                    <p><i class="pi pi-phone"></i> +265 992 562 686</p>
                    <p><i class="pi pi-envelope"></i> ipcs&#64;ipcs.mw</p>
                </div>
                <div class="office-info">
                    <h4>Lilongwe Branch</h4>
                    <p><i class="pi pi-map-marker"></i> Area 3, Behind NBS Bank, Next to Tutla Shop</p>
                    <p><i class="pi pi-phone"></i> +265 992 562 686</p>
                    <p><i class="pi pi-envelope"></i> ipcs&#64;ipcs.mw</p>
                </div>
            </div>
            <div class="contact-form">
                <h3>Send us a Message</h3>
                <form action="https://formspree.io/f/mdknlknp" method="POST">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" id="name" name="name" required pInputText>
                    </div>
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="text" id="email" name="email" required pInputText>
                    </div>
                    <div class="form-group">
                        <label for="message">Message</label>
                        <textarea id="message" name="message" required pInputTextarea></textarea>
                    </div>
                    <p-button type="submit" label="Send Message"></p-button>
                </form>
            </div>
        </div>
    </div>
</section>