<p-carousel [value]="slides" [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="5000">
    <ng-template pTemplate="item" let-slide>
        <div class="slide-wrapper">
            <div class="slide-content">
                <ng-container *ngIf="slide.type === 'text'">
                    <div class="text-content">
                        <h2>{{slide.title}}</h2>
                        <p>{{slide.content}}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="slide.type === 'image'">
                    <div class="image-content">
                        <img [src]="slide.imageUrl" [alt]="slide.alt" [class.first-image]="slide.isFirstSlide"
                            [ngStyle]="{'object-position': slide.objectPosition || 'center'}">
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</p-carousel>