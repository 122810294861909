import { Component } from '@angular/core';

@Component({
  selector: 'app-infrastructure',
  standalone: true,
  imports: [],
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.css', '.././products/products.component.css']
})
export class InfrastructureComponent {

}
