<div class="product-page infrastructure">
    <h1 class="section-title">Infrastructure Solutions</h1>

    <section class="product-section">
        <h2>Data Center Infrastructure Management (DCIM)</h2>
        <p>Vendor-neutral DCIM solutions offer resilient, secure, and sustainable IT infrastructure for single devices
            and large environments. Built for business continuity, our portfolio enables secure monitoring, management,
            planning, and services on-premises or cloud-based.</p>

        <h3>Monitoring and Management products include:</h3>
        <ul>
            <li>EcoStruxure IT Data Centre Expert</li>
            <li>EcoStruxure IT Expert</li>
            <li>EcoStruxure IT SmartConnect</li>
            <li>NetBotz</li>
            <li>Network Management Cards</li>
            <li>PowerChute Network Shutdown</li>
            <li>PowerChute Serial Shutdown</li>
        </ul>

        <p>For Planning and Modelling, take a look at our EcoStruxure IT Advisor</p>
        <div class="cta-links">
            <a href="https://www.se.com/za/en/work/campaign/data-centers-of-the-future/" target="_blank"
                class="cta-button">Learn More</a>
            <a href="https://ecostruxureit.com/" target="_blank" class="cta-button">Visit EcoStruxure IT</a>
        </div>
    </section>

    <section class="product-section">
        <h2>Edge Computing</h2>
        <p>Edge computing is designed to put applications and data closer to devices and their users. While cloud
            computing drove the creation of mega data centers, edge computing brings the power of distributed IT, spread
            across an exponential number of micro data centers.</p>
        <div class="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/MuVboT8QWIc" frameborder="0"
                allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
    </section>

    <section class="product-section">
        <h2>Micro Data Centers</h2>
        <p>EcoStruxure™ micro data centers are complete IT solutions that combine power, cooling, security, and
            monitoring in an enclosed S-Series, C-Series and R-Series rack system. A smaller footprint allows for
            flexible installation and our Local Edge Configurator simplifies deployment by helping you rapidly optimize
            edge computing solutions. Supported by EcoStruxure IT Software & Services, users can remotely monitor and
            manage for more informed, data-driven decisions.</p>
        <div class="model-viewer">
            <!-- Add 3D model viewer here -->
            <a href="https://skfb.ly/6WsXY" target="_blank" class="cta-button">View 3D Model</a>
        </div>
    </section>

    <section class="product-section">
        <h2>Security & Environmental Monitoring</h2>
        <p>Monitor and protect your IT infrastructure with certainty with APC NetBotz. Integrated surveillance, sensing,
            access control and instant alerting for IT environments of all sizes where IT rack space is unavailable.
            Supports up to 4 HD cameras, 16 wired sensors, 47 wireless sensors and badged access for 2 rack doors</p>
        <div class="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/7yh5v2Z8TAw" frameborder="0"
                allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
    </section>

    <section class="product-section">
        <h2>Cooling Solutions</h2>
        <p>The compaction of IT equipment and simultaneous increases in processor power consumption are creating
            challenges for data center managers in ensuring adequate distribution of cool air, removal of hot air and
            sufficient cooling capacity.</p>
        <h3>Cooling products include:</h3>
        <ul>
            <li>Chillers and Heat Rejection</li>
            <li>Close Coupled Air Conditioners</li>
            <li>Cooling Distribution and Piping</li>
            <li>Modular Access Floor</li>
            <li>Room Air Conditioners</li>
        </ul>
        <br>
        <a href="https://www.se.com/za/en/product-category/7200-cooling-solutions/" target="_blank"
            class="cta-button">Explore Cooling Solutions</a>
    </section>

    <section class="product-section">
        <h2>Software Solutions</h2>
        <p>Data Center Infrastructure Management (DCIM) collects, organizes, and distributes critical device information
            to provide a comprehensive view of your equipment, allowing you to focus on what matters most: efficient
            uptime and your own personal downtime.</p>
        <a href="https://www.EcoStruxureIT.com" target="_blank" class="cta-button">Visit EcoStruxure IT</a>
    </section>

    <section class="product-section">
        <h2>Home Automation Systems</h2>
        <p>With the Internet of Things taking, systems automation has made life simpler and homes smarter! Smart homes
            are the base to smart cities and are a more secure lifestyle. Home Automation System gives you the access to
            control the security of the house.</p>
        <h3>Options include:</h3>
        <ul>
            <li>C-Bus Home Automation</li>
            <li>KNX Home Automation</li>
            <li>Stand Alone Residential Temperature Control</li>
        </ul>
        <div class="cta-links">
            <a href="https://download.schneider-electric.com/files?p_Doc_Ref=BQT2430800_Quickstart_Guide"
                class="cta-button" target="_blank">C-Bus Quick Start Guide</a>
            <a href="https://download.schneider-electric.com/files?p_Doc_Ref=998-21035243" class="cta-button"
                target="_blank">KNX Brochure</a>
            <a href="https://download.schneider-electric.com/files?p_Doc_Ref=ISC02293EN" class="cta-button"
                target="_blank">Temperature Control Guide</a>
        </div>
    </section>
</div>