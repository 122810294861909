<nav class="custom-navbar">
    <div class="navbar-logo">
        <a routerLink="/">
            <img src="assets/img/IPCS-LOGO-2.jpg" alt="IPCS Logo">
        </a>
    </div>
    <button class="mobile-menu-toggle" (click)="toggleMobileMenu()">
        <i class="pi pi-bars"></i>
    </button>
    <div class="navbar-content" [class.mobile-menu-active]="isMobileMenuActive">
        <ul class="navbar-nav">
            <li class="nav-item dropdown" [class.active]="isProductsDropdownActive">
                <a (click)="toggleProductsDropdown($event)">
                    Products <span class="dropdown-arrow">▼</span>
                </a>
                <div class="dropdown-content">
                    <a routerLink="/products/power-control" (click)="closeMobileMenu()">Power Control</a>
                    <a routerLink="/products/infrastructure" (click)="closeMobileMenu()">Infrastructure</a>
                    <a routerLink="/products/renewable-energy" (click)="closeMobileMenu()">Renewable Energy</a>
                </div>
            </li>
            <li class="nav-item">
                <a routerLink="/about" (click)="closeMobileMenu()">About Us</a>
            </li>
            <li class="nav-item">
                <a routerLink="/contact" (click)="closeMobileMenu()">Contact Us</a>
            </li>
        </ul>
    </div>
</nav>