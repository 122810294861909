import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products/products.component';
import { InfrastructureComponent } from './products/infrastructure/infrastructure.component';
import { PowerControlComponent } from './products/power-control/power-control.component';
import { RenewableEnergyComponent } from './products/renewable-energy/renewable-energy.component';
import { ContactComponent } from './contact/contact.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contact', component: ContactComponent },
    {
        path: 'products',
        component: ProductsComponent,
        children: [
            { path: '', redirectTo: 'products', pathMatch: 'full' },
            { path: 'power-control', component: PowerControlComponent },
            { path: 'infrastructure', component: InfrastructureComponent },
            { path: 'renewable-energy', component: RenewableEnergyComponent }

        ]
    },
    { path: '**', redirectTo: '' },

];
